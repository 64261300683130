import * as React from "react";

import { PageWidgetSpec, Widget } from "../../";

import { CustomerLoyalty } from "./CustomerLoyalty";
import { FormSpec } from "../../../form-specs";
import { MxtsApi } from "@maxxton/cms-mxts-api";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { WidgetGroup } from "../../widget.enum";
import { getAdminMxtsEnv } from "../../mxts";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions {
    selectStatusCategory?: NumberMultiSelectOption[];
}

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "customer-loyalty-widget-options",
    name: getI18nLocaleObject(namespaceList.loyaltyWidget, "customerLoyaltyWidgetOption"),
    pluralName: getI18nLocaleObject(namespaceList.loyaltyWidget, "customerLoyaltyWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                variable: "selectStatusCategory",
                                label: getI18nLocaleObject(namespaceList.loyaltyWidget, "selectStatusCategory"),
                                type: "multiselect",
                                async optionList(): Promise<NumberMultiSelectOption[]> {
                                    const ops = await getAdminMxtsEnv();
                                    const customerStatusCategory = (await MxtsApi.getCustomerStatusCategory(ops, { size: 50 })).content;
                                    return customerStatusCategory.map(
                                        (statusCategory): NumberMultiSelectOption => ({
                                            value: statusCategory.categoryId,
                                            text: statusCategory.name,
                                        })
                                    );
                                },
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

export const customerLoyaltyWidget: PageWidgetSpec<WidgetOptions> = {
    id: "customerLoyalty",
    type: "page",
    widgetGroup: WidgetGroup.OTHER,
    name: getI18nLocaleObject(namespaceList.loyaltyWidget, "customerLoyaltyWidget"),
    description: getI18nLocaleObject(namespaceList.loyaltyWidget, "customerLoyaltyWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        selectStatusCategory: [],
    }),
    container: false,
    async render(widget: Widget<WidgetOptions>, context) {
        return <CustomerLoyalty options={widget.options} context={context} />;
    },
};
