import * as React from "react";

import { CustomerCluster, Image } from "@maxxton/cms-mxts-api";

import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { PageWidgetBaseProps } from "../pageWidget.types";
import { WidgetOptions } from "./";
import { getImageUrl } from "../../../components/media/SingleImage";
import { getMxtsEnv } from "../../mxts";
import { getMyEnvMainCustomer } from "../../../redux/reducers/myEnv/myEnv.util";

interface CustomerLoyaltyProps extends PageWidgetBaseProps<WidgetOptions> {
    context: CMSProviderProperties;
}

interface EnrichedCustomerClusters extends CustomerCluster {
    image: Image;
}

export const CustomerLoyalty = (props: CustomerLoyaltyProps): JSX.Element => {
    const { context, options } = props;
    const { selectStatusCategory } = options;
    const [customerClusters, setCustomerClusters] = React.useState<EnrichedCustomerClusters[]>([]);
    React.useEffect(() => {
        const getCustomer = async () => {
            const env = await getMxtsEnv(context);
            const customerData = await getMyEnvMainCustomer(env);
            const language = (await context.mxtsApi.languages(env, { shortName: context.currentLocale.code }))[0];
            if (customerData?.customerClusters) {
                let filteredCategory = customerData?.customerClusters;
                if (selectStatusCategory?.length) {
                    filteredCategory = customerData?.customerClusters.filter((customerCluster) =>
                        selectStatusCategory?.map((statusCategory) => statusCategory.value).includes(customerCluster.categoryId)
                    );
                }
                const imagePromises: Array<Promise<Image>> = [];
                filteredCategory.forEach((customerCluster: EnrichedCustomerClusters) => {
                    if (customerCluster.iconImageId) {
                        const image = context.mxtsApi.imageById(env, { imageId: customerCluster.iconImageId });
                        imagePromises.push(image);
                    }
                });
                const resolvedImages = await Promise.all(imagePromises);
                filteredCategory.forEach((customerCluster: EnrichedCustomerClusters) => {
                    const foundImage = resolvedImages.find((resolvedImage) => resolvedImage.imageId === customerCluster.iconImageId);
                    if (foundImage) {
                        customerCluster.image = foundImage;
                    }
                    const customerLoyaltyName = customerCluster.translations.find((translation) => translation.languageId === language.languageId)?.name;
                    if (customerLoyaltyName) {
                        customerCluster.name = customerLoyaltyName;
                    }
                });
                setCustomerClusters(filteredCategory);
            }
        };
        getCustomer();
    }, []);
    return (
        <div className="loyalty-wrapper">
            {customerClusters.map((customerCluster) => (
                <div className="loyalty-item" key={customerCluster.clusterId}>
                    {customerCluster.image && (
                        <div className="loyalty__img">
                            <img loading="lazy" src={getImageUrl(customerCluster.image)} />
                        </div>
                    )}
                    <div className="loyalty__status">{customerCluster.name}</div>
                </div>
            ))}
        </div>
    );
};
