import * as React from "react";

import { Image, ImageUrls } from "@maxxton/cms-mxts-api";
import { getDefaultFallBackImageUrls, getImages } from "./mxts-image-gallery/mxts";

import { Loader } from "../Loader";
import { globalApiContext } from "../../containers/CmsProvider";

export interface SingleImageProps {
    imageLoading?: boolean;
    image?: Image;
    imageManagerId?: number;
    fallbackImageManagerId?: number;
    localeCode?: string;
    className?: string;
    imageSize?: keyof ImageUrls;
    loaderType?: string;
    fallbackImage?: string;
}

export const SingleImage = (props: SingleImageProps): JSX.Element => {
    const { imageManagerId, fallbackImageManagerId, localeCode, className, fallbackImage, image: propsImage, imageSize, imageLoading, loaderType } = props;
    const apiContext = globalApiContext();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [obtainedImage, setObtainedImage] = React.useState<Image>();

    const image: Image | undefined = propsImage || obtainedImage;
    const imageUrl = getImageUrl(image, imageSize) || fallbackImage;
    React.useEffect(() => {
        (async () => {
            if (imageManagerId && !propsImage) {
                setLoading(true);
                const images: Image[] = await getImages(apiContext, imageManagerId, fallbackImageManagerId, localeCode, undefined, 1);
                setObtainedImage(images.length ? images[0] : undefined);
                setLoading(false);
            }
        })();
    }, [fallbackImageManagerId, imageManagerId, localeCode, propsImage]);

    if (loading || (imageLoading && !imageUrl)) {
        return <Loader type={loaderType || "image"} />;
    }

    return (
        <div className={className}>
            <img loading="lazy" src={imageUrl || getDefaultFallBackImageUrls().medium} className="card-img-top" />
        </div>
    );
};

export function getImageUrl(image?: Image, imageSize?: keyof ImageUrls) {
    let imageUrl = image?.urls?.[imageSize || "medium"]?.replace("t_newyse_", "t_mcms_");
    if (imageSize === "medium" && imageUrl) {
        imageUrl = imageUrl.replace("t_mcms_normal", "t_mcms_medium");
    }
    return imageUrl;
}
